import React from 'react';
import './project.css'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {getProjectInfo} from "../../data/projects";

const defaultInterval = 5000

export const Project = () => {

    const {t, i18n} = useTranslation();

    const {projectId} = useParams();

    const project = getProjectInfo(projectId)

    if (project === undefined) {
        return (
            <div className="page-content container">
                <div className="row text-center">
                    <h3>Oooops... I have no project like that!</h3>
                </div>
            </div>
        )
    }

    const slides = project.slides;

    const partner = project.partner ? t(project.partner.name).split(":")[0] : "";
    const partner_name = project.partner ? t(project.partner.name).split(":")[1] : "";

    return (
        <div className="page-content container">
            {/*<div className="row mb-3">*/}
            {/*    <div className="col-md-12 text-center">*/}
            {/*        <h1>{t(project.title)}</h1>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div id="projectCarousel" className="project-carousel carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {slides.map((slide, idx) =>
                                <button key={"btn-" + idx} type="button" data-bs-target="#projectCarousel" data-bs-slide-to={idx}
                                        className={idx === 0 ? "active" : ""}
                                        aria-current="true"/>
                            )}
                        </div>
                        <div className="carousel-inner">
                            {slides.map((slide, idx) =>
                                <div key={"slide-" + idx} className={idx === 0 ? "carousel-item active" : "carousel-item"} data-bs-interval={slide.interval}>
                                    <img key={"slide-img-" + idx} src={slide}/>
                                </div>
                            )}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#projectCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"/>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#projectCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"/>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 cn offset-md-1">
                    <div className="mb-4">
                        <h3>
                            {project.cn.title}
                        </h3>

                        {project.cn.award &&
                        <div className="">
                            奖项: {project.cn.award.link !== "#" ?
                            <a href={project.cn.award.link} target="_blank" rel="noreferrer">{project.cn.award.title}</a> : project.cn.award.title}
                        </div>
                        }
                        <div className="">
                            {project.cn.year}
                        </div>

                        {project.cn.partner &&
                        <div className="">
                            合作拍档: {project.cn.partner.link !== "#" ?
                            <a href={project.cn.partner.link} target="_blank" rel="noreferrer">{project.cn.partner.name}</a> : project.cn.partner.name}
                        </div>
                        }
                    </div>
                    <div className="project-description">
                        {project.cn.description}
                    </div>
                </div>
                <div className="col-md-5 mt-5 mt-md-0">
                    <div className="mb-4">
                        <h3>
                            {t(project.title)}
                        </h3>

                        {project.award &&
                        <div className="">
                            {t('award')}: {project.award.link !== "#" ?
                            <a href={project.award.link} target="_blank" rel="noreferrer">{t(project.award.title)}</a> : t(project.award.title)}
                        </div>
                        }

                        <div className="">
                            {t(project.year)}
                        </div>

                        {project.partner &&
                        <div className="">
                            {partner}: {project.partner.link !== "#" ?
                            <a href={project.partner.link} target="_blank" rel="noreferrer">{partner_name}</a> : partner_name}
                        </div>
                        }
                    </div>
                    <div className="project-description">
                        {t(project.description)}
                    </div>
                </div>
            </div>
            {project.video &&
            <div className="row mt-5">
                <div className="col-md-10 offset-1 video">
                    <video className="w-100" controls>
                        <source src={project.video} type="video/mp4"/>
                    </video>
                </div>
            </div>
            }
        </div>
    );
}