import './footer.css'
import behance from "../../media/behance.png";
import instagram from "../../media/instagram.png";

export const Footer = () => {
    return (
        <footer className="text-center mt-auto">
            <div>&copy; {new Date().getFullYear()} Suki Su</div>
{/*            <div className="footer-social">
                <div className="d-inline-block">
                    <a href="https://www.behance.net/suqiyi" target="_blank"><img src={behance} alt="suqiyi" /></a>
                </div>
                <div className="d-inline-block m-1">
                    <a href="https://www.instagram.com/suki_suuuu" target="_blank"><img src={instagram} alt="suki_suuuu" /></a>
                </div>
            </div>*/}

        </footer>
    )
}