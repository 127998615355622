import CultureSwitch_cover from '../media/projects/culture_switch/cover.jpg'
import CultureSwitch_01 from '../media/projects/culture_switch/CultureSwitch_01.jpg'
import CultureSwitch_02 from '../media/projects/culture_switch/CultureSwitch_02.jpg'
import CultureSwitch_03 from '../media/projects/culture_switch/CultureSwitch_03.jpg'
import CultureSwitch_04 from '../media/projects/culture_switch/CultureSwitch_04.jpg'
import CultureSwitch_05 from '../media/projects/culture_switch/CultureSwitch_05.jpg'
import CultureSwitch_06 from '../media/projects/culture_switch/CultureSwitch_06.jpg'
import CultureSwitch_07 from '../media/projects/culture_switch/CultureSwitch_07.jpg'
import CultureSwitch_08 from '../media/projects/culture_switch/CultureSwitch_08.jpg'
import CultureSwitch_09 from '../media/projects/culture_switch/CultureSwitch_09.jpg'
import CultureSwitch_10 from '../media/projects/culture_switch/CultureSwitch_10.jpg'
import CultureSwitch_11 from '../media/projects/culture_switch/CultureSwitch_11.jpg'
import CultureSwitch_12 from '../media/projects/culture_switch/CultureSwitch_12.jpg'

import Zeichensystem_cover from '../media/projects/letterpress/cover.jpg'
import Zeichensystem_001 from '../media/projects/letterpress/Zeichensystem_01.jpg'
import Zeichensystem_002 from '../media/projects/letterpress/Zeichensystem_02.jpg'
import Zeichensystem_003 from '../media/projects/letterpress/Zeichensystem_03.jpg'
import Zeichensystem_004 from '../media/projects/letterpress/Zeichensystem_04.jpg'
import Zeichensystem_005 from '../media/projects/letterpress/Zeichensystem_05.jpg'
import Zeichensystem_005b from '../media/projects/letterpress/Zeichensystem_05b.jpg'
import Zeichensystem_006 from '../media/projects/letterpress/Zeichensystem_06.jpg'
import Zeichensystem_006b from '../media/projects/letterpress/Zeichensystem_06b.jpg'
import Zeichensystem_007 from '../media/projects/letterpress/Zeichensystem_07.jpg'
import Zeichensystem_008 from '../media/projects/letterpress/Zeichensystem_08.jpg'
import Zeichensystem_009 from '../media/projects/letterpress/Zeichensystem_09.jpg'

import namenloseSchrift_cover from '../media/projects/font/cover.jpg'
import namenloseSchrift_001 from '../media/projects/font/namenloseSchrift_001.jpg'
import namenloseSchrift_002 from '../media/projects/font/namenloseSchrift_002.jpg'

import SchlafInfo_cover from '../media/projects/sleep/cover.jpg'
import SchlafInfo_02 from '../media/projects/sleep/SchlafInfo_02.jpg'

import Scham_cover from '../media/projects/shame/cover.jpg'
import Scham_001 from '../media/projects/shame/Scham_001.jpg'
import Scham_002 from '../media/projects/shame/Scham_002.jpg'
import Scham_003 from '../media/projects/shame/Scham_003.jpg'
import Scham_004 from '../media/projects/shame/Scham_004.jpg'
import Scham_005 from '../media/projects/shame/Scham_005.jpg'
import Scham_006 from '../media/projects/shame/Scham_006.jpg'
import Scham_007 from '../media/projects/shame/Scham_007.jpg'
import Scham_008 from '../media/projects/shame/Scham_008.jpg'
import Scham_009 from '../media/projects/shame/Scham_009.jpg'
import Scham_010 from '../media/projects/shame/Scham_010.jpg'

import Asian_cover  from '../media/projects/asian/cover.jpg'
import Chinesin  from '../media/projects/asian/01_Chinesin.jpg'
import Vietnamesin from '../media/projects/asian/02_Vietnamesin.jpg'
import Koreanerin from '../media/projects/asian/03_Koreanerin.jpg'
import Japanerin from '../media/projects/asian/04_Japanerin.jpg'


import GoNanting_cover from '../media/projects/nanting/cover.jpg'
import GoNanting_01 from '../media/projects/nanting/GoNanting_01.jpg'
import GoNanting_02 from '../media/projects/nanting/GoNanting_02.jpg'
import GoNanting_03 from '../media/projects/nanting/GoNanting_03.jpg'
import GoNanting_04 from '../media/projects/nanting/GoNanting_04.jpg'
import GoNanting_05 from '../media/projects/nanting/GoNanting_05.jpg'
import GoNanting_06 from '../media/projects/nanting/GoNanting_06.jpg'
import GoNanting_07 from '../media/projects/nanting/GoNanting_07.jpg'
import GoNanting_08 from '../media/projects/nanting/GoNanting_08.jpg'
import GoNanting_09 from '../media/projects/nanting/GoNanting_09.jpg'
import GoNanting_10 from '../media/projects/nanting/GoNanting_10.jpg'

import SchlafInfo_01_vid from '../media/projects/sleep/SchlafInfo_01.mp4'
import GoNantingMP4 from '../media/projects/nanting/GoNanting.mp4'
import Schlaf_video from '../media/projects/font/Schlaf_video.mp4'


export function getProjects(t) {
    return [
        {
            "id": "culture_switch",
            "img": CultureSwitch_cover,
            "caption": "culture_switch.title",
            "description": "culture_switch.short",
        },
        {
            "id": "letterpress",
            "img": Zeichensystem_cover,
            "caption": "letterpress.title",
            "description": "letterpress.short",
        },
        {
            "id": "font",
            "img": namenloseSchrift_cover,
            "caption": "font.title",
            "description": "font.short",
        },
        {
            "id": "sleep",
            "img": SchlafInfo_cover,
            "caption": "sleep.title",
            "description": "sleep.short",
        },
        {
            "id": "shame",
            "img": Scham_cover,
            "caption": "shame.title",
            "description": "shame.short",
        },
        {
            "id": "asian",
            "img": Asian_cover,
            "caption": "asian.title",
            "description": "asian.short",
        },
        {
            "id": "nanting",
            "img": GoNanting_cover,
            "caption": "nanting.title",
            "description": "nanting.short",
        },
    ]
}


const projectInfo = {
    "culture_switch": {
        slides: [CultureSwitch_01, CultureSwitch_02, CultureSwitch_03, CultureSwitch_04, CultureSwitch_05, CultureSwitch_06, CultureSwitch_07, CultureSwitch_08, CultureSwitch_09, CultureSwitch_10, CultureSwitch_11, CultureSwitch_12],
        title: "culture_switch.title",
        award: {
            title: "culture_switch.award",
            link: "https://www.stiftung-buchkunst.de/de/foerderpreis-fuer-junge-buchgestaltung/2021/preistraeger-2021.html?id=451",
        },
        year: "culture_switch.year",
        partner: {
            name: "culture_switch.partner",
            link: "https://alissaverj.com",
        },
        description: "culture_switch.description",
        cn: {
            title: "文化转换",
            award: {
                title: "德国书籍艺术基金会 - 2021青年书籍设计奖",
                link: "https://www.stiftung-buchkunst.de/de/foerderpreis-fuer-junge-buchgestaltung/2021/preistraeger-2021.html?id=451",
            },
            year: "时间: 2020",
            partner: {
                name: "Alissa Verj",
                link: "https://alissaverj.com",
            },
            description: "⪼文化转换：俄罗斯和中国与德国的相遇⪻ 这本书主要是论述了在德国，特别是在柏林的俄罗斯和中国文化。本书收集了与两种文化相关的视觉元素和对这两个文化的刻板印象。最后将俄罗斯与中国的视觉符号相互结合以及相互交换。\n\n" +
                "在这个视觉实验中收录了许多德国人脑海中对两个文化的偏见和刻板印象。但在各种偏见之中卻又有一部分是真实存在的。我们试图通过这本书將背后的故事以视觉化的方式來探讨和表现，并将其中的视觉特征凸显出来。\n\n" +
                "书籍的封面材料是特殊的装帧的亚麻布，封面的印刷则是用 PVC 颜料通过丝网印刷完成的。整本书籍的装订以及印刷是在柏林艺术大学的字体实验室和丝网工作室采用手工來制作。"
        }
    },
    "letterpress": {
        slides: [Zeichensystem_001, Zeichensystem_002, Zeichensystem_003, Zeichensystem_004, Zeichensystem_005, Zeichensystem_005b, Zeichensystem_006, Zeichensystem_006b, Zeichensystem_007, Zeichensystem_008, Zeichensystem_009],
        title: "letterpress.title",
        year: "letterpress.year",
        partner: {
            name: "letterpress.partner",
            link: "#",
        },
        description: "letterpress.description",
        cn: {
            title: "符号系统 - 铅字印刷",
            year: "时间: 2019",
            partner: {
                name: "Aaron Winter",
                link: "#",
            },
            description: "UdK的字体实验室中铅字印刷的器物以28个图形标志的形式展示出来，以便运用到印刷和平时工作中。这个符号系统在可能的情况下以等轴测法显示器皿和工具的位置，一些例外物体则是用俯视图和侧视图来显示。对于图形的封闭外观，则选择了大面积黑色的表现形式。该符号系统集旨在用于字体实验室的海报和带回家的传单中，让使用凸版印刷工作室的新手能够熟悉铅字印刷。\n\n" +
                "经过了大量的设计，这28个符号最终在Glyphs中呈现。最后的三张海报的图形部分是通过版画印刷的方式刻画出，而文字部分则是通过铅字印刷呈现。"
        }
    },
    "font": {
        slides: [namenloseSchrift_001, namenloseSchrift_002],
        video: Schlaf_video,
        title: "font.title",
        year: "font.year",
        description: "font.description",
        cn: {
            title: "无名字体",
            year: "时间：2020年至今",
            description: "这是一个仍进行中的项目。这款无名字体的灵感来自于新艺术主义，是一种可变的字体，由不同的字重组成。如果有想要试用，请给我发电子邮件。"
        }
    },
    "sleep": {
        slides: [SchlafInfo_02],
        video: SchlafInfo_01_vid,
        title: "sleep.title",
        year: "sleep.year",
        description: "sleep.description",
        cn: {
            title: "大量的睡眠需求",
            year: "时间: 2021",
            description: "这个信息设计是Suki做的一个自我的实验艺术，主要记录她在2020年11月9日到2021年1月31日睡眠时间。通过这个实验能够发掘出她具体的睡眠时间，她对于睡眠的大量需求，假期是否会影响她的睡眠状态？\n\n" +
                "根据一些科学研究，大多数人每天需要七到八个小时的睡眠时间。在这项信息设计中，低于平均睡眠需求，图形显示为蓝色，反之高于平均睡眠需求则为粉色。同时记录日出和日落的时间作为背景。\n\n" +
                "这个动态信息设计最后是在p5.js实现的。"
        }
    },
    "shame": {
        slides: [Scham_001, Scham_002, Scham_003, Scham_004, Scham_005, Scham_006, Scham_007, Scham_008, Scham_009, Scham_010],
        title: "shame.title",
        year: "shame.year",
        description: "shame.description",
        cn: {
            title: "羞愧",
            year: "时间：2019",
            description: "以情感作为出发点。“羞耻 / 羞愧”是我主要研究的主题。\n\n" +
                "第一步从大量的调研开始。在调研过程中，我无意中发现，部分的名人或者明星在尴尬的时刻并不表现出羞耻的样子。在第二个部分，我进一步研究在认知语言学和符号学中的重要概念-相似性。首先，通过拍摄表现羞耻感的不同照片，进而简化出抽象的图形。在第三部分，重新设计了与主题相关的信息图表。探究是羞耻感和外遇之间的联系。这就是为什么图表的标题是 \"外遇后你感到羞耻吗？\"。在第四部分，我制作了所谓的外遇指南。如果你想有一个安全的外遇，你应该阅读本指南。"
        }
    },
    "asian": {
        slides: [Chinesin, Vietnamesin, Koreanerin, Japanerin],
        title: "asian.title",
        year: "asian.year",
        description: "asian.description",
        cn: {
            title: "亚洲女性",
            year: "时间: 2018",
            description: "在柏林这段时间，我经常被问及 \"你是日本人吗？\"或者 \"你是韩国人吗？\"。有时同欧洲朋友出去，他们会问我：\"那个人是中国人吗？\"。我回答说：\"不，她是越南人\"。然后朋友会继续追问：\"你怎么知道的？\"。\n\n" +
                "但我无法准确回答这个问题。这就像一种直觉，告诉我这个人是越南人、日本人、中国人或韩国人。例如，我觉得能认出亞洲不通国家人的外表特征，根据我的经验大多数越南人和中国南方人个子相对矮小。但這不是明确的识别规则，对大多数德国人或欧洲人来说，要去判断一个亚洲人来自哪个国家却是一件困难的事情。\n\n这也就是 “如何识别亚洲女性？”这个项目诞生的背景。\n\n作为一个平面设计师，我经常尝试从另一个的角度去思考问题。\n\n极简主义图形，图标，以此类推到这个项目，在这里就转化为对一个人的身材、大小和颜色去分析。对于亚洲人来说，根据国籍，还可以从身高、体重、服装或皮色中收集信息。所以我到街上去寻找亚洲人，询问这些基本信息，同时也拍摄照片。最后分析了这些收集到的信息。"
        }
    },
    "nanting": {
        slides: [GoNanting_01, GoNanting_02, GoNanting_03, GoNanting_04, GoNanting_05, GoNanting_06, GoNanting_07, GoNanting_08, GoNanting_09, GoNanting_10],
        video: GoNantingMP4,
        title: "nanting.title",
        award: {
            title: "nanting.award",
            link: "#",
        },
        year: "nanting.year",
        description: "nanting.description",
        cn: {
            title: "趣南亭",
            award: {
                title: "最佳毕业设计",
                link: "#",
            },
            year: "时间：2016",
            description: "这是第四年以“设计广州”为毕设主题，而我所关注的是南亭。\n\n" +
                "南亭是个距离广州美术学院大学城校区很近的一个村庄。南亭里有着许多不同的(设计/艺术)工作室，该项目主旨是将这些不同的工作室组成为一个“整体”，通过毕业展的时间，能够让这些工作室更好的被发现，被认识。\n\n" +
                "项目名字中的“趣”一语双关，一方面是“趣味”的意思，另一方面是“去”的意思，在中文中读音相同。\n\n" +
                "我希望通过 qù 这个字引起观者对南亭的关注和产生拜访的兴趣。展览的形式也是为了呈现南亭村的复杂的生态环境，因此在展览中运用了许多电线和线条來表现原始场景，使观者能够身临其境。"
        }
    }
}

export function getProjectInfo(projectId) {
    return projectInfo[projectId]
}