import './App.css';
import React, {Suspense} from 'react';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import {Projects} from "./components/projects/projects";
import {Info} from "./components/info/info";
import {Header} from "./components/header/header";
import {Main} from "./components/main/main";
import {Project} from "./components/project/project";
import {Footer} from "./components/footer/footer";

function App() {
    return (
        <Suspense fallback="loading">
            <Router>
                <Header/>
                <Switch>
                    <Route path="/project/:projectId">
                        <Project/>
                        <Footer/>
                    </Route>
                    <Route path="/projects">
                        <Projects/>
                        <Footer/>
                    </Route>
                    <Route path="/info">
                        <Info/>
                        <Footer/>
                    </Route>
                    <Route path="/">
                        <Main/>
                    </Route>
                </Switch>
            </Router>
        </Suspense>
    );
}

export default App;
