import React from 'react';
import './main.css'
import {useTranslation} from 'react-i18next';
import home1 from '../../media/home/Home_001.jpg'
import home2 from '../../media/home/Home_002.jpg'
import home3a from '../../media/home/Home_003a.jpg'
import home4 from '../../media/home/Home_004.jpg'
import home5 from '../../media/home/Home_005.jpg'

const defaultInterval = 5000

export const Main = () => {

    const {t, i18n} = useTranslation();

    const slides = [
        {
            "img": home1,
            "class": "dark-bg",
            "caption": "",
            "description": "",
            "interval": defaultInterval
        },
        {
            "img": home2,
            "class": "white-bg",
            "caption": "",
            "description": "",
            "interval": defaultInterval
        },
        {
            "img": home3a,
            "class": "dark-bg",
            "caption": "",
            "description": "",
            "interval": defaultInterval
        },
        {
            "img": home4,
            "class": "white-bg",
            "caption": "",
            "description": "",
            "interval": defaultInterval
        },
        {
            "img": home5,
            "class": "white-bg",
            "caption": "",
            "description": "",
            "interval": defaultInterval
        },
    ]

    return (
        <>
            <main>
                <div id="mainCarousel" className="main-carousel carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div className="carousel-indicators">
                        {slides.map((slide, idx) =>
                            <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to={idx} className={idx === 0 ? "active" : ""}
                                    aria-current="true" aria-label={slide.caption} key={`btn-${idx}`}/>
                        )}
                    </div>
                    <div className="carousel-inner">
                        {slides.map((slide, idx) =>
                            <div className={slide.class + " " + (idx === 0 ? "carousel-item active" : "carousel-item")} data-bs-interval={slide.interval} key={`slide-${idx}`}>
                                {/*<img src={slide.img} alt={slide.caption}  />*/}
                                <div className="main-carousel-img" style={{backgroundImage: 'url(' + slide.img + ')'}} key={`img-${idx}`}/>
                                {/*<div className="carousel-caption d-none d-md-block" key={`ca`}>*/}
                                {/*    <h5>{slide.caption}</h5>*/}
                                {/*    <p>{slide.description}</p>*/}
                                {/*</div>*/}
                            </div>
                        )}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#mainCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#mainCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </main>
        </>
    );
}