import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Language} from "./lang/lang";
import './header.css'

export const Header = () => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <header>
                <nav className="navbar top-menu navbar-expand-md navbar-light fixed-top">
                    <div className="container-fluid top-menu-container">
                        <a className="navbar-brand" href="/">Suki Su</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">{t('home')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/projects">{t('projects')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/info">{t('info')}</Link>
                                </li>
                            </ul>
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <div className="dropdown-divider"/>
                                    <div className="nav-link">
                                        <Language/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}