import React from 'react';
import './projects.css'
import {useTranslation} from "react-i18next";
import {getProjects} from "../../data/projects";
import {Link} from "react-router-dom";


export const Projects = () => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="page-content container">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {getProjects(t).map(project =>
                        <div className="col">
                            <div className="card project-card">
                                <div className="project-card-inner">
                                    <img src={project.img} alt={t(project.caption)}/>
                                    <Link to={`/project/${project.id}`} className="project-card-text p-4">
                                        <div>
                                            <h2>{t(project.caption)}</h2>
                                            <p>{t(project.description)}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}