import React from 'react';
import './info.css'
import {useTranslation} from "react-i18next";
import behance from '../../media/behance.png'
import wechat from '../../media/wechat.png'
import email from '../../media/email.png'
import instagram from '../../media/instagram.png'


export const Info = () => {

    const {t, i18n} = useTranslation();

    function getEnBio() {
        return <div>
            Suki is a graphic designer focusing on infographics, book design and typography.<br/><br/>

            She was born and raised in China. She completed her bachelor's degree in graphic design at the <a target="_blank" href="https://www.gzarts.edu.cn">Guangzhou Academy of Fine Arts</a>. Since 2017 Suki has been living in Berlin. In 2019 she studied a semester in Visual Communication at <a target="_blank" href="https://www.zhdk.ch">Zurich University of the Arts</a>. After that, she study her Master in the <a target="_blank" href="http://infoklasse.de">Information Design</a> class with Prof. David Skopec at the <a target="_blank" href="https://www.udk-berlin.de/startseite/">Berlin University of the Arts</a>.<br/><br/>

            Currently she is working together at the <a target="_blank" href="https://wzb.eu/de">WZB Berlin Social Science Center</a>.
        </div>
    }

    function getDeBio() {
        return <div>
            Suki ist eine Grafikdesignerin und fokussiert sich auf Infografik, Buchgestaltung und Typografie.<br/><br/>

            Sie ist in China geboren und aufgewachsen. Sie hat ihr Bachelorstudium im Fach Grafikdesign an der <a target="_blank" href="https://www.gzarts.edu.cn" rel="noreferrer">Guangzhou Academy of Fine Arts</a> absolviert. Seit 2017 lebt Suki in Berlin und hat 2019 ein Semester im Fach Visuelle Kommunikation an der <a target="_blank" href="https://www.zhdk.ch">Zürcher Hochschule der Künste</a>, studiert. Nachdem studiert sie ihren Master in der Klasse <a target="_blank" href="http://infoklasse.de">Informationsgestaltung</a> bei Prof. David Skopec an der <a target="_blank" href="https://www.udk-berlin.de/startseite/">Universität der Künste Berlin</a>.<br/><br/>

            Zurzeit arbeitet sie beim <a href="https://wzb.eu/de">Wissenschaftszentrum Berlin für Sozialforschung</a> zusammen.
        </div>
    }

    return (
        <div className="page-content container">
            <div className="row bio">
                <div className="col-md-6 cn">
                    苏奇夷是一名平面设计师，主要专注于信息设计, 书籍设计和字体设计。<br/><br/>
                    她在中国出身和长大。在<a target="_blank" href="https://www.gzarts.edu.cn">广州美术学院</a>完成了本科平面设计学习。 从2017年起生活于柏林，并于2019年在<a target="_blank" href="https://www.zhdk.ch">苏黎世艺术大学</a>进修了一学期。 之后她在<a target="_blank" href="https://www.udk-berlin.de/startseite/">柏林艺术大学</a> - <a target="_blank" href="http://infoklasse.de">信息专业</a> 继续攻读研究生，师从 David Skopec。 <br/><br/>
                    目前她同时与<a target="_blank" href="https://wzb.eu/de">柏林社会学研究中心</a>合作。
                </div>
                <div className="col-md-6 mt-5 mt-md-0">
                    {i18n.language === "en" ? getEnBio() : getDeBio()}
                </div>
            </div>
            <div className="row mt-5 social">
                <div className="col-md-6 cn">
                    <h5>联系方式</h5>
                    <p>
                        <div>
                            <img src={wechat} alt="suqiyi" /> suqiyi
                        </div>
                        <div className="pt-1">
                            <a href="mailto:sukiyi94@gmail.com"><img src={email} alt="email" /> sukiyi94@gmail.com</a>
                        </div>
                    </p>
                </div>
                <div className="col-md-6 mt-5 mt-md-0">
                    <h5>{t('contact')}</h5>
                    <p>
                        <div>
                            <a href="https://www.behance.net/suqiyi" target="_blank" rel="noreferrer"><img src={behance} alt="suqiyi" /> suqiyi</a>
                        </div>
                        <div className="pt-1">
                            <a href="https://www.instagram.com/suki_suuuu" target="_blank" rel="noreferrer"><img src={instagram} alt="suki_suuuu" /> suki_suuuu</a>
                        </div>
                        <div className="pt-1">
                            <a href="mailto:sukiyi94@gmail.com"><img src={email} alt="email" /> sukiyi94@gmail.com</a>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
}